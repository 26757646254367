import React from "react";
import ExperienceCard from "./Experiences";
import infy from "../Assets/infosys.jpg";
import techM from "../Assets/techM.webp";

const Experiences = () => {
  return (
    <div
      className="w-full flex flex-col items-center gap-6 mt-8 mx-4"
      id="work-experience"
    >
      <h2 className="text-3xl  text-white font-semibold mb-6">Experience</h2>

      <div className="flex flex-col lg:flex-row gap-2 justify-center w-full">
        <ExperienceCard
          companyName="Infosys"
          position="System Engineer"
          duration="Sep 2021 - Dec 2022"
          //   location="Houston, TX"
          bgColor="bg-gray-800"
          logo={infy}
        />
        <ExperienceCard
          companyName="Tech Mahindra"
          position="Software Engineer"
          duration="Jan 2021 - Aug 2022"
          location="Hyderabad, India"
          bgColor="bg-gray-600"
          logo={techM}
        />

        {/* <ExperienceCard
          companyName="Tech Mahindra"
          position="Software Engineer"
          duration="Jan 2021 - Aug 2022"
          location="Hyderabad, India"
          bgColor="bg-gray-600"
          logo={techM} // Replace with Sureify logo URL
        /> */}
      </div>
    </div>
  );
};

export default Experiences;
