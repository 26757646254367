import React from "react";
import npmlogo from "../../Assets/npmlogo.webp";
const NPMpackages = () => {
  return (
    <div>
      <div
        className="bg-gray-900 text-white min-h-max flex flex-col items-center justify-center"
        id="npm-packages"
      >
        <h1 className="text-3xl  text-white  text-left font-bold m-10">
          NPM Packages
        </h1>
        <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-7xl p-8">
          <div className="flex flex-col items-center">
            <img
              src={npmlogo} // Replace with actual logo/image URL
              alt="Auth Zen Logo"
              className="w-40 h-40 mb-6"
            />
            <h2 className="text-2xl font-semibold mb-4">Quick Form Builder</h2>
            <p className="text-gray-400 text-center mb-6">
              Quick Form Builder simplifies form creation with an intuitive
              interface. It enables developers to design and deploy fully
              customizable forms quickly, complete with validation and
              responsive design capabilities.
            </p>
            <div className="flex space-x-4">
              <a
                href="https://github.com/prudhvij15/react-form-builder"
                className="bg-blue-600 hover:bg-blue-500 text-white py-2 text-sm px-3 no-underline rounded-lg transition duration-300"
              >
                View Code
              </a>
              <a
                href="https://www.npmjs.com/settings/prudhvi1929/packages"
                className="bg-purple-600 hover:bg-purple-500 text-white text-sm py-2 px-3 no-underline rounded-lg transition duration-300"
              >
                View in NPM Registry
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NPMpackages;
