import React from "react";

const ExperienceCard = ({
  companyName,
  position,
  duration,
  location,
  bgColor,
  logo,
}) => {
  return (
    <div
      className={`rounded-lg shadow-lg p-6 ${bgColor} text-white flex flex-col items-center h-94 w-2/5 `}
    >
      <div className="bg-black rounded-full p-2 mb-4">
        <img
          src={logo}
          alt={`${companyName} logo`}
          className="h-18 w-20  rounded-full"
        />
      </div>
      <h3 className="text-xl font-bold">{companyName}</h3>
      <p className="text-lg font-medium mt-2">{position}</p>
      <p className="text-sm mt-1">{duration}</p>
      <p className="text-sm mt-1">{location}</p>
    </div>
  );
};

export default ExperienceCard;
